import React from 'react';
import {BottomSheet} from 'react-spring-bottom-sheet';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './style.scss';

// https://react-spring-bottom-sheet.cocody.dev/
function Bottomsheet(props) {
  const getPassingProps = () => {
    const nonAllowedPassingProps = ['dismissable', 'onDismiss', 'children', 'scaled'];
    return Object.keys(props).reduce((previousValue, currentValue) => {
      if (!nonAllowedPassingProps.includes(currentValue)) {
        return {...previousValue, [currentValue]: props[currentValue]};
      }
      return previousValue;
    }, {});
  };

  return (
    <BottomSheet
      {...getPassingProps()}
      className={clsx(
        styles.HuiBottomsheet,
        !props.dismissable && styles['HuiBottomsheet--no-handle'],
        props.className,
        props.scaled && styles['HuiBottomsheet--scaled'],
      )}
      onDismiss={props.dismissable && props.onDismiss}
    >
      {props.children}
    </BottomSheet>
  );
}

Bottomsheet.defaultProps = {
  open: false,
  dismissable: true,
  scaled: false,
  onDismiss: () => null,
  snapPoints: ({minHeight}) => [Math.min(minHeight, window.innerHeight * 0.8)]
};
Bottomsheet.propTypes = {
  open: PropTypes.bool.isRequired,
  snapPoints: PropTypes.func,
  defaultSnap: PropTypes.func,
  header: PropTypes.element,
  footer: PropTypes.element,
  blocking: PropTypes.bool,
  scrollLocking: PropTypes.bool,
  expandOnContentDrag: PropTypes.bool,
  dismissable: PropTypes.bool,
  scaled: PropTypes.bool,
  onDismiss: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  className: PropTypes.string
};

export default Bottomsheet;

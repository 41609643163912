import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// CSS Themes
import COLORS from '../../../themes/_colors_v2.module.scss';

// Local styles
import styles from './style.scss';

const PulseLoader = (props) => {
  // Render
  return (
    <div className={styles.HuiPulseLoader}>
      {[1, 2, 3].map((each, i) => (
        <div
          key={i}
          style={{
            width: props.size,
            height: props.size,
            backgroundColor: props.color
          }}
          className={clsx(
            styles['HuiPulseLoader__item'],
            styles[`HuiPulseLoader__item--${i + 1}`]
          )}
        />
      ))}
    </div>
  );
};


PulseLoader.defaultProps = {
  size: 16,
  color: COLORS.primaryDefault
};

PulseLoader.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default PulseLoader;

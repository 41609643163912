import React, {useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/pro-solid-svg-icons';

import styles from './style.scss';

function Modal(props) {
  const isInitial = useRef(true);
	const originalBodyOverflow = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    // add is initial logic to prevent close modal function from firing
    if (isInitial.current && !props.open) {
      isInitial.current = false;
      return;
    }
    if (props.open) {
      // store body's inline overflow style before overriding with hidden so that it can be correctly reverted when closed
      originalBodyOverflow.current = document.body.style.overflow;
      document.body.style.overflow = 'hidden';
      setShowModal(true);
      setTimeout(() => {
        setShowContent(true);
        setTimeout(() => {
          props.onModalOpen();
        }, props.animationDuration);
      }, 10);
    } else {
      document.body.style.overflow = originalBodyOverflow.current;
      setShowContent(false);
      setTimeout(() => {
        setShowModal(false);
        props.onModalClosed();
      }, props.animationDuration);
    }
  }, [props.open]);

  return (
    showModal && (
      <div
        className={clsx(
          styles.HuiModal,
          props.enableOverlay && styles['HuiModal__overlay'],
          showContent && styles['HuiModal--open'],
          props.localScoped && styles['HuiModal--scoped'],
          // props.scaled && styles['HuiModal--scaled'],
        )}
        style={{
          transition: `${props.animationDuration}ms`,
          zIndex: props.zIndex
        }}
      >
        <div
          className={clsx(
            styles.HuiModal__content,
            props.fullscreen && styles['HuiModal__content--fullscreen'],
            styles[`HuiModal__content--${props.animationType}`],
            showContent && styles['HuiModal__content--open']
          )}
          onClick={props.onBackdropPress}
          style={{
            transition: `${props.animationDuration}ms`
          }}
        >
          <div
            className={clsx(styles.HuiModal__children, props.className)}
            style={props.style}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {props.children}
          </div>
          {props.renderCloseButton && (
            <div
              className={styles['HuiModal__close-button']}
              onClick={(e) => {
                e.stopPropagation();
                props.onClose();
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          )}
        </div>
      </div>
    )
  );
}

Modal.defaultProps = {
  open: false,
  fullscreen: false,
  renderCloseButton: false,
  enableOverlay: true,
  localScoped: false,
  scaled: false,
  animationType: 'fade',
  animationDuration: 400,
  zIndex: 1,
  className: '',
  style: {},
  onBackdropPress: () => null,
  onClose: () => null,
  onModalOpen: () => null,
  onModalClosed: () => null
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  fullscreen: PropTypes.bool,
  renderCloseButton: PropTypes.bool,
  enableOverlay: PropTypes.bool,
  localScoped: PropTypes.bool,
  scaled: PropTypes.bool,
  animationType: PropTypes.oneOf([
    'fade',
    'slideUp',
    'slideDown',
    'slideLeft',
    'slideRight'
  ]),
  animationDuration: PropTypes.number,
  zIndex: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  style: PropTypes.object,
  onBackdropPress: PropTypes.func,
  onClose: PropTypes.func,
  onModalOpen: PropTypes.func,
  onModalClosed: PropTypes.func
};

export default Modal;

export const pxToVwMaxed = (pxValue, includePxUnit = false) => {
  const designWidth = getComputedStyle(document.documentElement).getPropertyValue('--ui-kit-design-width');
  const maxpxToVwMaxed = getComputedStyle(document.documentElement).getPropertyValue('--ui-kit-scale-max-width');
  if (!designWidth) {
    return pxValue;
  }
  const parsedPxValue = parseInt(pxValue);
  const designMobileWidth = parseInt(designWidth);
  const maxpxToVwMaxedParsed = parseInt(maxpxToVwMaxed) || 5000;

  const scaledSize = (parsedPxValue * window.innerWidth) / designMobileWidth;
  const maxPossibleSize = (parsedPxValue * maxpxToVwMaxedParsed) / designMobileWidth;

  if (parsedPxValue < 0) {
    const maxResult = Math.max(scaledSize, maxPossibleSize);
    return includePxUnit ? `${maxResult}px` : maxResult;
  }
  const minResult = Math.min(scaledSize, maxPossibleSize);
  return includePxUnit ? `${minResult}px` : minResult;
};

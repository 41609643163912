import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Local styles
import styles from './style.scss';

// Fontawesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {faSquare, faCircle} from '@fortawesome/pro-regular-svg-icons';
import {
  faSquare as faSquareSolid,
  faCircle as faCircleSolid,
  faDotCircle,
  faSquareCheck
} from '@fortawesome/pro-solid-svg-icons';

// CSS Themes
import COLORS from '../../themes/_colors_v2.module.scss';

const RadioCheckboxButton = ({
  type = 'radio',
  size = 'medium',
  checkIcon = type === 'checkbox' ? faSquareCheck : faDotCircle,
  checked = false,
  disabled = false,
  onPress = null,
  style,
  className,
  iconClassName,
}) => {
  const sizeStyle = size === 'medium';

  const renderIndicator = () => {
    const iconColor = disabled
      ? COLORS.textDisabled
      : checked
      ? COLORS.successDefault
      : COLORS.textDisabled;
    const typeCheck = checked
      ? checkIcon
      : type === 'checkbox'
      ? faSquare
      : faCircle;
    const iconDisabled = type === 'checkbox' ? faSquareSolid : faCircleSolid;
    return (
      <FontAwesomeIcon
        className={clsx(!disabled && styles.icon, iconClassName)}
        icon={disabled && !checked ? iconDisabled : typeCheck}
        size={!sizeStyle && 'lg'}
        color={iconColor}
        onClick={disabled ? null : onPress}
      />
    );
  };

  return (
    <div className={clsx(styles.iconContainer, className)} style={style}>
      {renderIndicator()}
    </div>
  );
};

RadioCheckboxButton.propTypes = {
  type: PropTypes.oneOf(['radio', 'checkbox']),
  size: PropTypes.string,
  checkIcon: PropTypes.object,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onPress: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
};

export default RadioCheckboxButton;
